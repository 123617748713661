import { createApp } from "vue";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";

import App from "./App.vue";

import router from "./router";

import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import i18n from "@/i8n";
import VueCountdown from "@chenfengyuan/vue-countdown";
import Vue3TouchEvents from "vue3-touch-events";
import { vMaska } from "maska";

import "element-plus/dist/index.css";
import "./assets/css/less/styles.less";
import "./assets/main.scss";
import mitt from "mitt";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "@/graphql/init";
import { fpjsPlugin } from "@fingerprintjs/fingerprintjs-pro-vue-v3";

import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import { createHead } from "unhead";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import vue3GoogleLogin from "vue3-google-login";

const emitter = mitt();

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://7f0f400f9c4d5035632658a388b15881@sentry.necasino.dev/1",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router);
const head = createHead();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
app.use(head);

app.use(vue3GoogleLogin, {
  clientId:
    "223763693881-pndfm9kqe1mo8batvi21ua5vocagnoac.apps.googleusercontent.com",
});

app.use(pinia);
app.use(i18n);
app.use(Vue3TouchEvents);
app.use(VueTippy, {
  directive: "tippy",
  component: "tippy",
  componentSingleton: "tippy-singleton",
  defaultProps: {
    placement: "top",
    allowHTML: true,
    hideOnClick: false,
    zIndex: 9999,
  },
});
app.use(fpjsPlugin, {
  loadOptions: {
    apiKey: "NmXiUAlhcObI4bdHJpLJ",
  },
});
app.use(OneSignalVuePlugin, {
  appId: "dd42c905-e5e9-4235-b260-90a05365cff3",
});

app.component(VueCountdown.name, VueCountdown);

app.directive("maska", vMaska);

app.provide("emitter", emitter);
app.provide(DefaultApolloClient, apolloClient);

app.mount("#app");

import { defineStore } from "pinia";
import {
  siteUserType,
  balanceObject,
  userRewardInterface,
  StatusLevelInterface,
  wagerType,
} from "@/types/siteTypes";
import axios, { AxiosError } from "axios";
import { api } from "@/http/api";
import { UserWalletType } from "@/types/walletTypes";
import { stat } from "fs";
import { IGetGamesResData } from "@/store/modules/games/entities";

function getInitialLastViewedNotificationId() {
  const storedValue = localStorage.getItem("lastViewedNotificationId");
  if (storedValue === null) {
    return null;
  }

  const parsedValue = parseInt(storedValue);
  if (!isNaN(parsedValue)) {
    return parsedValue;
  }

  return null;
}

export const useUserStore = defineStore("user", {
  persist: true,
  state: () => ({
    user: null as siteUserType | null,
    language: null as string | null,
    lastViewedNotificationId: getInitialLastViewedNotificationId(),
    lastViewedDatePopupRewards: null as Date | null,
    isTodayRewardTaken: null as boolean | null,
    userPaymentWallet: null as UserWalletType | null,
    tempUserAvatarObject: null as any,
    newRewardsCount: null as number | null,
    userChallengesRewards: null as null | userRewardInterface[],
    telegramBotNotificationLink: null as null | string,
    userStatusProgressInfo: null as null | StatusLevelInterface,
    periodFromLastWheelSpin: null as null | number,
    isFortuneWheelNotAvailable: null as null | number,
    dateOfLastSpin: null as null | Date,
    isBigWinSubscriptionCompleted: null as boolean | null,
    userWager: null as wagerType | null,
    isBuyAvailable: false,
  }),
  getters: {
    getUser: (state) => state.user,
    getAvatarImage: (state) => state.user?.avatar_info.images,
    getUserBalance: (state) => state.user?.money,
    getUserEmail: (state) => state.user?.email,
    getUserCurrency: (state) => state.user?.currency,
    getTournament: (state) => state.user?.selectedTournament,
    getUserTournament: (state) => state.user?.selectedTournament?.tournamentId,
    getLanguage: (state) => state.language,
    getLastViewedDatePopupRewards: (state) => state.lastViewedDatePopupRewards,
    getIsTodayRewardTaken: (state) => state.isTodayRewardTaken,
    getUserStatus: (state) => state.user?.status_id,
    getUserPaymentWallet: (state) => state.userPaymentWallet,
    getTempAvatar: (state) => state.tempUserAvatarObject,
    getNewRewardsCount: (state) => state.newRewardsCount,
    getUserChallengesRewards: (state) => state.userChallengesRewards,
    getTelegramBotNotificationLink: (state) =>
      state.telegramBotNotificationLink,
    getUserNextStatusInfo: (state) => state.userStatusProgressInfo,
    getPeriodFromLastWheelSpin: (state) => state.periodFromLastWheelSpin,
    checkIfUserCannotSpinWheel: (state) => state.isFortuneWheelNotAvailable,
    getDateOfLastWheelSpin: (state) => state.dateOfLastSpin,
    getIfBigWinSubscriptionCompleted: (state) =>
      state.isBigWinSubscriptionCompleted,
    getUserWager: (state) => state.userWager,
    getBuyAvailability: (state) => state.isBuyAvailable,
  },
  actions: {
    async fetchWager() {
      try {
        const { data } = await api.get<wagerType>(
          "/users-wagers/necacoin-balances"
        );
        this.userWager = data;
      } catch (err) {
        if (axios.isAxiosError(err)) {
          const error: AxiosError = err;
          if (!error.response) {
            throw err;
          }
        }
        throw err;
      }
    },

    setUser(user: siteUserType | null) {
      this.user = user;
      this.userChallengesRewards = null;

      if (user === null) {
        this.setNewRewardsCount(null);
        this.setTelegramBotNotificationLink(null);
        this.setUserPaymentWallet(null);
        this.setUserStatusProgressInfo(null);
        this.setIsFortuneWheelNotAvailable(null);
        this.setPeriodFromLastWheelSpin(null);
        this.setDateOfLastWheelSpin(null);
        this.setBigWinSubscriptionCompleted(null);
      }
    },
    setUserPaymentWallet(wallet: UserWalletType | null) {
      if (this.user) this.userPaymentWallet = wallet;
    },
    setUserMoney(money: number) {
      if (this.user) this.user.money = money;
    },
    setUserEmail(email: string) {
      if (this.user) this.user.email = email;
    },
    setUserRating(rating: number) {
      if (this.user) this.user.rating = rating;
    },
    setUserWins(wins: number) {
      if (this.user) this.user.wins = wins;
    },
    setUserBids(bids: number) {
      if (this.user) this.user.bids = bids;
    },
    setUserPrice(price: number) {
      if (this.user) this.user.price = price;
    },
    setLanguage(language: string) {
      this.language = language;
      localStorage.setItem("userLanguage", language);
    },
    setTournament(tournament: balanceObject | null) {
      if (this.user) {
        this.user.selectedTournament = tournament;

        if (tournament?.userBalance) {
          this.updateTournamentBalance(tournament.userBalance);
        }
      }
    },
    updateTournamentBalance(balanceValue: number) {
      if (this.user && this.user.selectedTournament) {
        this.user.selectedTournament.userBalance = balanceValue;
        this.user.money = balanceValue;
      }
    },
    setTempAvatar(avatarObject: any) {
      this.tempUserAvatarObject = avatarObject;
    },
    setLastViewedNotificationId(id: number) {
      this.lastViewedNotificationId = id;
      localStorage.setItem("lastViewedNotificationId", id.toString());
    },
    setLastViewedDatePopupRewards(date: Date) {
      if (this.user) {
        this.lastViewedDatePopupRewards = date;
      }
    },
    updateUserData(tournamentId: number) {
      //this.setUserMoney(0);

      api
        .get("service-tournaments/user-info", {
          params: {
            tournamentId: tournamentId,
          },
        })
        .then((res) => {
          this.setUserMoney(res.data.balance);
          this.setUserRating(res.data.rating);
        });
    },
    setNewRewardsCount(value: number | null) {
      this.newRewardsCount = value;
    },
    addChallengesReward(reward: userRewardInterface) {
      if (this.userChallengesRewards) {
        for (let i = 0; i < this.userChallengesRewards.length; i++) {
          if (
            this.userChallengesRewards[i].source_tag &&
            this.userChallengesRewards[i].source_tag === "CHALLENGE_EMAIL" &&
            reward.source_tag == "CHALLENGE_EMAIL"
          ) {
            this.userChallengesRewards.splice(i, 1);
            this.userChallengesRewards.push(reward);
            break;
          } else if (
            this.userChallengesRewards[i].source_tag &&
            this.userChallengesRewards[i].source_tag === "CHALLENGE_TELEGRAM" &&
            reward.source_tag == "CHALLENGE_TELEGRAM"
          ) {
            this.userChallengesRewards.splice(i, 1);
            this.userChallengesRewards.push(reward);
            break;
          } else {
            this.userChallengesRewards.push(reward);
          }
        }
      } else {
        this.userChallengesRewards = [reward];
      }
    },
    updateChallengesRewards() {
      api
        .get(
          "/rewards/getUserRewards?sources=CHALLENGE_TELEGRAM,CHALLENGE_EMAIL",
          {
            params: {
              page: 1,
            },
          }
        )
        .then((res) => {
          const rewards = res.data.data as userRewardInterface[];
          // console.log(rewards)

          for (const reward of rewards) {
            this.addChallengesReward(reward);
          }

          this.setNewRewardsCount(res.data.total_new);
        });
    },
    setTelegramBotNotificationLink(value: string | null) {
      this.telegramBotNotificationLink = value;
    },
    setUserStatusProgressInfo(info: StatusLevelInterface | null) {
      this.userStatusProgressInfo = info;
    },
    setIsFortuneWheelNotAvailable(value: number | null) {
      this.isFortuneWheelNotAvailable = value;
    },
    setPeriodFromLastWheelSpin(value: number | null) {
      this.periodFromLastWheelSpin = value;
    },
    setDateOfLastWheelSpin(date: Date | null) {
      this.dateOfLastSpin = date;
    },
    setBigWinSubscriptionCompleted(value: boolean | null) {
      this.isBigWinSubscriptionCompleted = value;
    },
  },
});
